import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import Button from '../components/page/button/button';
import HeadingAndText from '../components/page/heading-and-text/heading-and-text';
import ImageHeader from '../components/page/image-header/image-header';
import { getHeaderImageSlice, getHeadingAndTextSlice } from '../utils/slices';

export const pageQuery = graphql`
  {
    posts: allPrismicBlogPost(sort: {fields: first_publication_date, order: DESC}) {
    nodes {
      uid
      publish_date: first_publication_date(formatString: "DD.MM.YYYY")
      data {
        title {
          text
        }
        image {
          url
        }
        preview_image {
          url
        }
        description {
          html
        }
      }
    }
  }
}
`
// TODO: Refactor this component and blog-posts.jsx
export default ({ data }) => (
  <Layout title="Blog" description="Newsbeiträge und Neuigkeiten aus dem Osternest">
    <ImageHeader slice={getHeaderImageSlice('/images/musterhaus-uerzig.jpg')}/>
    <HeadingAndText slice={getHeadingAndTextSlice('Blog')}/>
    <div className="container my-5">
        <div className="row">
            {data.posts.nodes.length > 0 && data.posts.nodes.map((node, index) => (
                <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
                    <Link className="unstyled" to={`/blog/${node.uid}`}>
                        <img className="w-100 mb-1" src={node.data.preview_image.url ? node.data.preview_image.url : node.data.image.url}></img>
                        <small className="d-block mb-2 color-grey">{node.publish_date}</small>
                        <h2 className="mb-3">{node.data.title.text}</h2>
                        <div dangerouslySetInnerHTML={{ __html: node.data.description.html }}></div>
                        <Button text="Mehr erfahren" link={`/blog/${node.uid}`} color="green" small/>
                    </Link>
                </div>
            ))}
        </div>
    </div>
  </Layout>
);
